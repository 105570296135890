// author:马云阳；description:众惠-公募-填写转换信息2
<template>
    <section class="PublicFundProcess">
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont">
            <a @click="toGos" class="title_">
            <span class="iconfont">&#xe665;</span> 公募基金
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
           <div class="card_">
              <div class="titles_">
                <div class="left_ti">转换基金</div>
              </div>
              <div class="turn_">
                  <div class="card_s left_card">
                    <img class="card_ti" src="@/static/img/my/gm_out_.png" alt="">
                    <div class="card_txt">
                      <div class="txt_">
                        <div class="left_txt">转出基金:</div>
                        <div class="right_txt">{{datas.changedata.out.fundName}}</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">可用份额:</div>
                        <div class="right_txt">{{datas.fundshare||0}}份</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">转出份额:</div>
                        <div class="right_txt">{{datas.fundshare||0}}份</div>
                      </div>
                    </div>
                  </div>
                  <div class="card_s right_card">
                    <img class="card_ti" src="@/static/img/my/gm_into_.png" alt="">
                    <div class="card_txt">
                      <div class="txt_">
                        <div class="left_txt">转入基金:</div>
                        <div class="right_txt">{{datas.changedata.info.fundName}}</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">转入基金收费方式:</div>
                        <div class="right_txt">前端收费</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">转入份额:</div>
                        <div class="right_txt">{{datas.fundshare||0}}份</div>
                      </div>
                      <!-- <div class="txt_">
                        <div class="left_txt">交易所属日期:</div>
                        <div class="right_txt">2021/08/14</div>
                      </div> -->
                    </div>
                  </div>
              </div>
              <div class="inputs_">
                <div class="title_input">
                  <span class="l_ti">请输入交易密码:</span>
                  <a class="r_ti" @click="forgetPoss">忘记密码</a>
                </div>
                <div class="inputs_boxs">
                  <input style="width:100%;height:100%;" :maxlength="6" v-model="password" placeholder="请输入六位交易密码" type="password">
                </div>
                <span v-if="showerr" class="err">{{errmsg}}</span>
              </div>
              <div class="topage_btn">
                <a @click="getbtn?config():''" class="topage_">确定</a>
                <a @click="togos" class="navback">返回上一步</a>
              </div>
          </div>
          
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import { conversion } from "@/api/outfund";

export default {
  name: "PublicFundProcessthree",
  components: { ProgressBar },
  data() {
    return {
      side: [
        { title: "选择转换基金", type: 1 },
        { title: "填写转换信息", type: 1 },
        { title: "输入转换密码", type: 2 },
        { title: "交易结果", type: 0 }
      ],
      datas: {},
      password: "",
      errmsg: "",
      showerr:false,
      getbtn:true
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push('/myFavors/accountManage/safetyCentre')
    },
    togos() {
      this.$router.go(-1);
    },
    toGos() {
      this.$router.push({name:"PublicFund"})
    },
    config() {
      
      if (this.password && this.password.length < 6) {
        //   this.$me.catch(e=>{
        //     this.$message.error(res.msg);
        // })
        this.$message.error('请正确输入密码！');
      } else {
        this.getbtn=false
        let paramss = {
          tradeAcco: this.datas.bank.tradeAcco,
          password: this.password,
          fundCode: this.datas.changedata.out.fundCode,
          targetFundCode:this.datas.changedata.info.fundCode,
          clientSource:1
        };
        conversion(paramss).then(res=>{
          if(res.code==200){
          this.showerr=true;
            let date = new Date();
            let okey={datas:this.datas.changedata,fundshare:this.datas.fundshare,dates:date};
            this.$router.push({name:'PublicFundProcessfour',params:{okey}})
          }
        }).catch(e=>{
          this.getbtn=true;
          this.showerr=true;
          this.errmsg=e
        })
      }
    }
  },
  created() {
    this.datas = this.$route.params.params;
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  padding: 0px 0px 50px 0;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 40px;
      margin-top: 30px;
      .titles_ {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        color: #1f1f1f;
      }
      .turn_ {
        margin-top: 70px;
        display: flex;
        align-items: center;

        .card_s {
          position: relative;
          width: 357px;
          height: 220px;
          &:first-child {
            height: 155px;
            padding-top: 65px;
          }
          &:last-child {
            height: 165px;
            padding-top: 55px;
          }
          .card_ti {
            position: absolute;
            width: 105px;
            height: 60px;
            top: -30px;
            z-index: 99;
            left: 135px;
          }
        }
        .left_card {
          background: linear-gradient(296deg, #5f6b93 0%, #bcc6db 100%);
          border-radius: 4px;
          margin-right: 60px;
          .card_txt {
            .txt_ {
              display: flex;
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ffffff;
              justify-content: center;
              margin-bottom: 22px;
              .left_txt {
                text-align: right;
                width: 37%;
                padding-right: 3%;
              }
              .right_txt {
                width: 60%;
                text-align: left;
              }
            }
          }
        }
        .right_card {
          background: linear-gradient(296deg, #cca376 0%, #f2e2d1 100%);
          border-radius: 4px;
          .card_txt {
            .txt_ {
              display: flex;
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ffffff;
              justify-content: center;
              margin-bottom: 20px;
              .left_txt {
                text-align: right;
                width: 47%;
                padding-right: 3%;
              }
              .right_txt {
                width: 50%;
                text-align: left;
              }
            }
          }
        }
      }
      .inputs_ {
        width: 520px;
        margin-top: 40px;
        .title_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .l_ti {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #1f1f1f;
          }
          .r_ti {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #a5a5a5;
          }
        }
        .inputs_boxs {
          display: flex;
          align-items: center;
          width: 494px;
          height: 46px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          padding-left: 24px;
          margin-top: 14px;
        }
        .err{
          color: red;
          margin: 10px 0px;
        }
      }
      .topage_btn {
        margin-top: 40px;
        display: flex;
        align-items: center;
        .topage_ {
          display: inline-block;
          width: 240px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          color: #ce9b63;
          background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          font-size: 16px;
          margin-right: 20px;
        }
        .navback {
          display: inline-block;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ce9b63;
        }
      }
    }
  }
}
</style>
